<template>
  <div >    
   
          <div class="formBox" v-if="showForm">
              <span class="t">{{addForm.id?'编辑片区':'新增片区'}}</span>
              <el-divider></el-divider>
                  <el-form ref="addForm" class="searchForm"  :model="addForm" :rules="rules" label-width="80px">
                        <el-form-item label="片区名称" prop="networkName" size="mini">
                          <el-input v-model="addForm.networkName"  placeholder="请输入片区名称"></el-input>
                        </el-form-item>
                        <el-form-item label="排序" prop="sort" size="mini">
                          <el-input-number v-model="addForm.sort" controls-position="right" :min="0" ></el-input-number>
                        </el-form-item>
                        <el-form-item label-width="0px" class="searchBt">
                          <el-button
                            size="mini"
                            type="primary"
                            class="searchBtn"
                            @click="save"
                            >保存</el-button
                          >
                          <el-button
                            size="mini"
                            class="searchBtn"
                            @click="closePDraw"
                            >取消</el-button
                          >
                        </el-form-item>
                  </el-form>
          </div>

  </div>
</template>

<script>
import {saveNetwork } from '@/apis/commonType'
export default {
  name: 'formEdit',
  props:{
    showForm:{
      type:Boolean,
      default:false
    }, 
    addForm:{
      type:Object,
      default: function(){
        return {}
      }
    },
    polyline1:{
      type:Object,
      default: null
    }
  },
  data() {
    return {
      name: '片区',
      rules: {
        networkName: [
          { required: true, message: '请输入片区名称', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'change' }
        ]
      },
    }
  },
  computed: {},
  mounted() {
    let inputArray = document.getElementsByClassName('el-input__inner')
    inputArray.forEach((item,index)=>{
      item.setAttribute('maxlength',5)
    })
  },
  methods: {
    resetFields(){
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields()
      }
    },
    save(){
      if(this.polyline1){
          this.addForm.location=this.polyline1.getPath()
      }
      let path=this.addForm.location
      if(path&&path.length>=3){
        var area = AMap.GeometryUtil.ringArea(path);
        if(area<=0){
          this.$message.error('请绘制正确的片区')
          return
        }        
      }else{        
        this.$message.error('请绘制正确的片区')
        return
      }
      
      let newPath=[]
      path.forEach((item,index)=>{
        let nextItem=path[(index+1)]
        if(nextItem){
          if(nextItem.lng==item.lng&&item.lat==nextItem.lat){
          }else{
            newPath.push(item)
          }
        }else{
           newPath.push(item)
        }        
      })  
      this.addForm.location=newPath
    
      this.$refs['addForm'].validate(valid => {
          if (valid) {              
               let parames=this.addForm

               let pathP=this.addForm.location

               if(pathP[0].lng!=pathP[pathP.length-1].lng&&pathP[0].lat!=pathP[pathP.length-1].lat){
                  parames.location=[
                  ...this.addForm.location,
                  this.addForm.location[0]
                  ]
               }                 
              
               
                saveNetwork(parames).then(res => {
                if(res.code==200){
                  this.$message.success('操作成功')
                  this.$emit('save')
                }                
              })
          }
      })
      
    },
    closePDraw(){
      this.$emit('closePDraw')
    }
   
  }
}
</script>

<style lang="less" scoped>

.searchForm{margin:15px 15px 0 5px;}
.searchBt{text-align: center;}
.formBox{position:absolute;right:0;top:100px;background: #fff;width: 300px;padding-top:10px;z-index: 3;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
}
</style>
